import React from 'react';
import './Spinner.css';

const Spinner = () => (
  <div className="container">
    <div className="dash first"></div>
    <div className="dash second"></div>
    <div className="dash third"></div>
    <div className="dash fourth"></div>
  </div>
);

export default Spinner;
