import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const Product = () => {
  const [foods, setProduct] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://treats4trojansnodeapi.onrender.com/api/products');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProduct(data);
        setOriginalData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setErrorMessage('Could not fetch products, please try again later.');
        setShowErrorModal(true);
      }
    };

    fetchData();

    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []);

  const filterType = (category) => {
    setProduct(originalData.filter((item) => item.category === category));
  };

  const filterPrice = (price) => {
    setProduct(originalData.filter((item) => item.price === price));
  };

  const addToCart = (item) => {
    let storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    
    // Ensure we're consistently using 'id'
    const existingItemIndex = storedCart.findIndex(cartItem => cartItem.id === item.id);

    if (existingItemIndex !== -1) {
      // Increase quantity if item exists in cart
      storedCart[existingItemIndex].quantity += 1;
    } else {
      // Add new item with quantity 1
      storedCart.push({ ...item, quantity: 1 });
    }

    setCart(storedCart);
    localStorage.setItem('cart', JSON.stringify(storedCart));
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <div className='max-w-[1240px] m-auto px-4 py-12'>
      <h1 className='text-black-600 font-bold text-4xl text-center'>Top Rated Items</h1>
      <br />
      <h4 className='text-black-600 font-bold text-2xl text-center'>
        Available in USA 🇺🇸- Canada 🇨🇦 - Dubai 🇦🇪 - Australia 🇦🇺 - New Zealand 🇳🇿 - Saudi Arabia 🇸🇦 - Africa 🌍 & <br/> South America 🌎 
      </h4>
      <br />
      <div className='flex flex-col lg:flex-row justify-between'>
        {/* Filter Type */}
        <div>
          <p className='font-bold text-gray-700'>Filter Type</p>
          <div className='flex justify-between flex-wrap'>
            <button onClick={() => setProduct(originalData)} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>All</button>
            <button onClick={() => filterType('JACKED JOURNEY WAY PROTEIN')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>JACKED JOURNEY WAY PROTEIN</button>
            <button onClick={() => filterType('SHAKERS')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>ELECTRIC SHAKERS</button>
            <button onClick={() => filterType('VIDEO SCROLLER RING')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>VIDEO SCROLLER RING</button>
            <button onClick={() => filterType('BCAAZ GUMMIES')} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>BCAAZ GUMMIES</button>
          </div>
        </div>

        {/* Filter Price */}
        <div>
          <p className='font-bold text-gray-700'>Filter Price</p>
          <div className='flex justify-between flex-wrap max-w-[390px] w-full'>
            <button onClick={() => filterPrice(30)} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>$30.00</button>
            <button onClick={() => filterPrice(40)} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>$40.00</button>
            <button onClick={() => filterPrice(10)} className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white border rounded-xl px-5 py-1'>$10.00</button>
          </div>
        </div>
      </div>

      {/* Display Products */}
      <AnimatePresence>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
          {foods.map((item) => (
            <motion.div
              layout
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              key={item.id}
              className='border shadow-lg rounded-lg hover:scale-105 duration-500 cursor-pointer'
            >
              <img className='w-full h-[300px] md:h-[280px] object-cover rounded-t-lg' src={item.image} alt={item.name} />
              <div className='flex justify-between px-2 py-4'>
                <p>{item.name}</p>
                <p>
                  <span className='bg-orange-500 text-white p-1 rounded-md'>${item.price}.00</span>
                </p>
              </div>
              <div className='px-2 pb-4'>
                <button onClick={() => addToCart(item)} className='bg-orange-500 text-white px-3 py-1 rounded-md hover:bg-orange-700'>Add to Cart</button>
              </div>
            </motion.div>
          ))}
        </div>
      </AnimatePresence>

      {/* Success Modal */}
      <AnimatePresence>
        {showSuccessModal && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className='fixed inset-0 flex items-center justify-center bg-opacity-50 '
          >
          <div className='bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-auto my-4 sm:max-w-lg'>
            <h2 className='text-2xl font-bold mb-4 text-center'>Success!</h2>
            <p className='mb-4 text-center text-gray-700'>Item added to cart!</p>
            <div className="flex justify-center space-x-4">
              <button onClick={closeSuccessModal} className='bg-orange-500 text-white px-6 py-2 rounded-md hover:bg-orange-700'>
                Continue shopping
              </button>
              <Link to="/cart" className="bg-orange-500 text-white px-6 py-2 rounded-md hover:bg-orange-700">
                Go to cart
              </Link>
            </div>
          </div>

          </motion.div>
        )}
      </AnimatePresence>

      {/* Error Modal */}
      {showErrorModal && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.3 }}
          className='fixed inset-0 flex items-center justify-center bg-opacity-50'
        >
          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <h2 className='text-xl font-bold mb-4'>Error</h2>
            <p className='mb-4'>{errorMessage}</p>
            <button onClick={closeErrorModal} className='bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700'>Close</button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Product;
