import React, { useState, useEffect, useCallback, useRef } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Navbar from "./Navbar";
import Spinner from "./Spinner"; // Adjust the path as necessary

function Message({ content }) {
  return <p>{content}</p>;
}

function Pay() {
  const initialOptions = {
    "client-id": "AQJB7_ZDef5Cui-k2dBtjPqx34rzg4drQ3W584z1qBiIzqPyz0eAD3B-1YPN7jjr5h6Ijx83okqNE8cw",
    currency: "USD",
  };

  const [message, setMessage] = useState("");
  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const cartRef = useRef(cart); // Create a ref to hold the latest cart

  const fetchCart = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('access_token');

      if (!token) {
        // If not logged in, get cart from local storage
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        if (storedCart.length === 0) {
          setMessage("Your cart is currently empty.");
        } else {
          setCart(storedCart);
          cartRef.current = storedCart; // Update the ref with stored cart data
        }
      } else {
        // If logged in, fetch cart data from API
        const response = await fetch('https://treats4trojansnodeapi.onrender.com/api/cart', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch cart data');
        }

        const cartData = await response.json();

        // Set the cart state only if data is valid
        if (Array.isArray(cartData) && cartData.length > 0) {
          setCart(cartData);
          cartRef.current = cartData; // Update the ref with new cart data
        } else {
          console.log("Cart is empty or invalid data received");
          setMessage("Your cart is currently empty.");
        }
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
      setMessage("Error fetching cart data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCart();
  }, []);

  const sendEmailConfirmation = async (transaction, shippingAddress, customerName) => {
    const promoCode = localStorage.getItem('appliedPromoCode'); // Get the applied promo code
  
    try {
      const response = await fetch('https://treats4trojansnodeapi.onrender.com/api/send-confirmation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transaction,
          cart: cartRef.current,
          promoCode, // Include promo code in the request body
          shippingAddress, // Include shipping address
          customerName,    // Include customer name
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send confirmation email');
      }
  
      // Save email details in the database
      const emailResponse = await fetch('https://treats4trojansnodeapi.onrender.com/api/save-email-details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transactionId: transaction.id,
          payerEmail: transaction.payer.email_address,
          cart: cartRef.current,
          promoCode,
          shippingAddress,
          customerName
        }),
      });
  
      if (!emailResponse.ok) {
        throw new Error('Failed to save email details');
      }
  
      console.log('Email confirmation sent and saved successfully');
    } catch (error) {
      console.error("Error sending email confirmation or saving email details:", error);
      setMessage("Error sending email confirmation");
    }
  };
  

  const createOrder = useCallback(async (data, actions) => {
    if (!cartRef.current || cartRef.current.length === 0) {
      setMessage("Cart is empty. Please add items to your cart.");
      return actions.reject(); // Reject if cart is empty
    }

    const items = cartRef.current.map(item => {
      if (!item.name || !item.price || !item.quantity || !item.id) {
        console.error("Invalid item found in cart:", item);
        return null;
      }

      return {
        name: item.name,
        unit_amount: {
          currency_code: "USD",
          value: item.price.toFixed(2),
        },
        quantity: item.quantity.toString(),
        sku: item.id.toString(),
      };
    }).filter(item => item !== null);

    if (items.length === 0) {
      setMessage("No valid items found in cart. Please check your cart.");
      return actions.reject();
    }

    const itemTotal = items.reduce((acc, item) =>
      acc + (parseFloat(item.unit_amount.value) * parseInt(item.quantity)), 0);

    const taxTotal = 0.00;
    const shipping = 0.00;
    const handling = 0.00;
    const insurance = 0.00;
    const shippingDiscount = 0.00;

    const totalAmountBeforeDiscount = itemTotal + taxTotal + shipping + handling + insurance - shippingDiscount;

    const discountFactor = localStorage.getItem('appliedPromoCode') ? 0.15 : 0; // 15% discount
    const discountAmount = totalAmountBeforeDiscount * discountFactor;
    const finalAmount = (totalAmountBeforeDiscount - discountAmount).toFixed(2);

    return actions.order.create({
      purchase_units: [{
        amount: {
          currency_code: "USD",
          value: finalAmount,
          breakdown: {
            item_total: {
              currency_code: "USD",
              value: itemTotal.toFixed(2),
            },
            tax_total: {
              currency_code: "USD",
              value: taxTotal.toFixed(2),
            },
            shipping: {
              currency_code: "USD",
              value: shipping.toFixed(2),
            },
            handling: {
              currency_code: "USD",
              value: handling.toFixed(2),
            },
            insurance: {
              currency_code: "USD",
              value: insurance.toFixed(2),
            },
            shipping_discount: {
              currency_code: "USD",
              value: shippingDiscount.toFixed(2),
            },
            discount: {
              currency_code: "USD",
              value: discountAmount.toFixed(2),
            },
          },
        },
        items: items,
      }],
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="Pay">
        {isLoading && <Spinner />}
        <PayPalScriptProvider options={initialOptions}>
          <div className="paypal-button-container">
            <PayPalButtons
              style={{
                shape: "rect",
                layout: "vertical",
                color: "gold",
                label: "paypal",
                height: 55,
                width: 300,
              }}
              createOrder={createOrder}
              disabled={isLoading || cart.length === 0}
              onApprove={async (data, actions) => {
                try {
                  const response = await fetch(
                    `https://treats4trojansnodeapi.onrender.com/api/orders/${data.orderID}/capture`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  const orderData = await response.json();

                  if (orderData.status === "COMPLETED") {
                    const transaction = orderData.purchase_units[0].payments.captures[0];
                    const shippingAddress = orderData.purchase_units[0].shipping.address;
                    const customerName = orderData.payer.name.given_name + " " + orderData.payer.name.surname;

                    setMessage(
                      `Transaction ${transaction.status}: ${transaction.id}. `
                    );

                    sendEmailConfirmation(transaction, shippingAddress, customerName);

                    localStorage.removeItem('appliedPromoCode');
                    localStorage.removeItem('cart');
                    localStorage.removeItem('colorSelections');
                    console.log("Capture result", orderData, JSON.stringify(orderData, null, 2));
                  } else {
                    throw new Error(`Failed to capture PayPal payment`);
                  }
                } catch (error) {
                  console.error(error);
                  setMessage(`Sorry, your transaction could not be processed...${error}`);
                }
              }}
            />
          </div>
        </PayPalScriptProvider>
        <Message content={message} />
      </div>
    </>
  );
}

export default Pay;
