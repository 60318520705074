import React, { useState, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if this is the first time the app is opened
    const isFirstOpen = localStorage.getItem('isFirstOpen');

    if (!isFirstOpen) {
      // If it's the first open, log out the user
      localStorage.removeItem('access_token');
      setIsLoggedIn(false);
      localStorage.setItem('isFirstOpen', 'true'); // Set the flag to indicate the app has been opened
    } else {
      const token = localStorage.getItem('access_token');
      if (token) {
        setIsLoggedIn(true);
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://treats4trojansnodeapi.onrender.com/api/products');
        const data = await response.json();
        if (searchQuery) {
          // Filter data based on search query
          const filtered = data.filter(item =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setFilteredData(filtered);
        } else {
          setFilteredData(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [searchQuery]);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    setIsLoggedIn(false);
    navigate('/');
  };

  const handleCartClick = () => {
    navigate('/cart'); // Always navigate to the cart, regardless of login status
  };

  const addToCart = async (item) => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      setShowPopup(true);
      return;
    }

    try {
      const response = await fetch('https://treats4trojansnodeapi.onrender.com/api/cart', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ product_id: item.id, quantity: 1 }),
      });
      const data = await response.json();
      if (response.ok) {
        setShowSuccessModal(true);
      } else {
        alert('Error adding item to cart: ' + data.message);
      }
    } catch (error) {
      console.error('Error adding item to cart:', error);
      alert('Error adding item to cart');
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row max-w-[1240px] mx-auto items-center p-4 justify-between">
        <div className="cursor-pointer mb-4 sm:mb-0">
          <h1 className="font-bold text-3xl sm:text-4xl lg:text-4xl">
            <a href="/">
              <img className="max-h-[70px] object-cover rounded-xl mb-4" src="/treats4trojanslogo.png" alt="" />
            </a>
            TRILLI ON TROJAN TREATS
          </h1>
        </div>
        <div className="flex flex-col sm:flex-row sm:ml-auto items-center w-full sm:w-auto">
          <div className="flex justify-between bg-gray-200 h-10 rounded-md pl-2 w-full sm:w-[400px] lg:w-[400px] items-center mb-4 sm:mb-0">
            <input
              type="search"
              className="flex bg-transparent p-2 w-full focus:outline-none text-black"
              placeholder="search.."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <FiSearch size={20} className="bg-black text-white p-[10px] h-10 rounded-md w-10 font-bold" />
            <FontAwesomeIcon 
              icon={faCartShopping} 
              size="2x" 
              className="ml-2 cursor-pointer"
              onClick={handleCartClick}
            />
          </div>
          <div className="login w-full sm:w-auto sm:ml-2">
            {isLoggedIn ? (
              <button onClick={handleLogout} className="bg-orange-500 text-white px-5 py-2 h-10 w-full sm:w-[100px] rounded-md">
                Logout
              </button>
            ) : (
              <Link to="/login" className="bg-orange-500 text-white px-5 py-2 h-10 w-full sm:w-[100px] rounded-md">
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
      {searchQuery && filteredData.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-2 p-2">
          {filteredData.map(item => (
            <div key={item.id} className="mb-2 p-4 bg-white rounded shadow">
              <img src={item.image} alt={item.name} className="w-30 h-32 object-cover rounded mb-2" />
              <h2 className="text-xl font-bold">{item.name}</h2>
              <p className="text-gray-700">{item.category}</p>
              <p className="text-orange-500">${item.price}.00</p>
              <div className='px-2 pb-4'>
                {/* <button
                  onClick={() => addToCart(item)}
                  className='bg-orange-500 text-white px-3 py-1 rounded-md hover:bg-orange-700'
                >
                  Add to Cart
                </button> */}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Popup for login alert */}
      {/* <AnimatePresence>
        {showPopup && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className='fixed inset-0 flex items-center justify-center bg-opacity-50'
          >
            <div className='bg-white p-6 rounded-lg shadow-lg'>
              <h2 className='text-xl font-bold mb-4'>Sign In Required!</h2>
              <p className='mb-4'>Please sign in to add items to your cart.</p>
              <button
                onClick={closePopup}
                className='bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-700'
              >
                Close
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence> */}

      {/* Success Modal */}
      {/* <AnimatePresence>
        {showSuccessModal && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className='fixed inset-0 flex items-center justify-center bg-opacity-50'
          >
            <div className='bg-white p-6 rounded-lg shadow-lg'>
              <h2 className='text-xl font-bold mb-4'>Success</h2>
              <p className='mb-4'>Item added to your cart successfully!</p>
              <button
                onClick={closeSuccessModal}
                className='bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-700'
              >
                Close
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence> */}
    </>
  );
}

export default Navbar;
