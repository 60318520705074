import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Spinner from './Spinner';
import Modal from './Modal';

const AdminDashboard = () => {
  const [admin, setAdmin] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newProduct, setNewProduct] = useState({
    name: '',
    price: '',
    category: '',
    image: '',  // Store image URL as a string
    quantity: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const response = await fetch('http://localhost:8000/api/admin_details', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        const data = await response.json();
        setAdmin(data.admin);
      } catch (error) {
        console.error('Error fetching admin details:', error);
      }
    };

    fetchAdminDetails();
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/products', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log('Full API Response:', data); // Check the structure of the response
      setProducts(data); // Set products directly if the response is an array
    } catch (error) {
      console.error('Error fetching products:', error);
      setProducts([]);
    } finally {
      setLoading(false);
    }
  };
  

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/admin/login');
  };

  const handleAddProductFormSubmit = async (event) => {
    event.preventDefault();

    const productData = {
      name: newProduct.name,
      price: parseFloat(newProduct.price),
      category: newProduct.category,
      image: newProduct.image, // Use the image URL directly
      quantity: parseInt(newProduct.quantity, 10)
    };

    try {
      const response = await fetch('http://localhost:8000/api/products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(productData), // Send the product data as JSON
      });

      if (response.ok) {
        const addedProduct = await response.json();
        setProducts([...products, addedProduct.product]);
        setMessage('Product added successfully!');
        setShowModal(true);
        setNewProduct({
          name: '',
          price: '',
          category: '',
          image: '', // Reset the image URL
          quantity: ''
        });
      } else {
        setMessage('Failed to add product.');
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error adding product:', error);
      setMessage('Error adding product.');
      setShowModal(true);
    }
  };

  const handleDeleteProduct = async (productId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this product?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`http://localhost:8000/api/products/${productId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (response.ok) {
        setProducts(products.filter(product => product.id !== productId));
        setMessage('Product deleted successfully!');
        setShowModal(true);
      } else {
        setMessage('Failed to delete product.');
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      setMessage('Error deleting product.');
      setShowModal(true);
    }
  };

  const handleEditProductFormSubmit = async (event) => {
    event.preventDefault();

    const updatedProductData = {
      name: selectedProduct.name,
      price: parseFloat(selectedProduct.price),
      category: selectedProduct.category,
      image: selectedProduct.image,
      quantity: parseInt(selectedProduct.quantity, 10)
    };

    try {
      const response = await fetch(`http://localhost:8000/api/products/${selectedProduct.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(updatedProductData),
      });

      if (response.ok) {
        const updatedProduct = await response.json();
        setProducts(products.map(product => 
          product.id === updatedProduct.id ? updatedProduct : product
        ));
        setMessage('Product updated successfully!');
        setShowModal(true);
        setIsEditModalVisible(false);
        setSelectedProduct(null);
      } else {
        setMessage('Failed to update product.');
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error updating product:', error);
      setMessage('Error updating product.');
      setShowModal(true);
    }
  };

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gray-100 p-8">
        {loading ? (
          <Spinner />
        ) : (
          <div className="max-w-6xl mx-auto">
            {admin && (
              <div className="mb-8 text-center">
                <h1 className="text-4xl font-bold">Welcome, {admin.username}!</h1>
                <p className="text-lg">{admin.email}</p>
              </div>
            )}

            <div className="flex justify-end mb-4">
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Logout as Admin
              </button>
            </div>

            <h2 className="text-2xl font-bold mb-4">Add New Product</h2>
            <form onSubmit={handleAddProductFormSubmit} className="bg-white p-6 rounded-lg shadow-md mb-8">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Name:</label>
                <input
                  type="text"
                  value={newProduct.name}
                  onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Price:</label>
                <input
                  type="number"
                  step="0.01"
                  value={newProduct.price}
                  onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Category:</label>
                <input
                  type="text"
                  value={newProduct.category}
                  onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Quantity:</label>
                <input
                  type="number"
                  value={newProduct.quantity}
                  onChange={(e) => setNewProduct({ ...newProduct, quantity: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Image URL:</label>
                <input
                  type="text"
                  value={newProduct.image}
                  onChange={(e) => setNewProduct({ ...newProduct, image: e.target.value })}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Add Product
              </button>
            </form>

            <h2 className="text-2xl font-bold mb-4">Product List</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
  {products.length > 0 ? (
    products.map((product) => (
      <div key={product.id} className="bg-white p-4 rounded-lg shadow-md">
        <img 
          src={product.image} 
          alt={product.name} 
          className="w-full h-48 object-cover mb-4 rounded-lg" 
        />
        <h3 className="text-xl font-bold mb-2">{product.name}</h3>
        <p className="text-gray-700 mb-2">{product.category}</p>
        <p className="text-gray-700 mb-2">Price: ${product.price !== null ? product.price.toFixed(2) : 'N/A'}</p>
        <p className="text-gray-700 mb-4">Quantity: {product.quantity}</p>
        {/* Edit and Delete Buttons */}
        <div className="flex justify-between mt-4">
                    <button 
                      onClick={() => {
                        setSelectedProduct(product);  // Set the selected product for editing
                        setIsEditModalVisible(true);  // Show the edit modal
                      }}
                      className="bg-yellow-500 text-white px-2 py-1 rounded"
                    >
                      Edit
                    </button>
                      <button 
                        onClick={() => handleDeleteProduct(product.id)}
                        className="bg-red-500 text-white px-2 py-1 rounded"
                      >
                        Delete
                      </button>
                    </div>
      </div>
    ))
  ) : (
    <p className="col-span-full text-center text-gray-700">No products available.</p>
  )}
</div>

{isEditModalVisible && selectedProduct && (  // Ensure selectedProduct is not null
  <Modal onClose={() => setIsEditModalVisible(false)}>
    <h2 className="text-2xl font-bold mb-4">Edit Product</h2>
    <form onSubmit={handleEditProductFormSubmit} className="bg-white p-6 rounded-lg shadow-md">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Name:</label>
        <input
          type="text"
          value={selectedProduct.name || ''}
          onChange={(e) => setSelectedProduct({ ...selectedProduct, name: e.target.value })}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Price:</label>
        <input
          type="number"
          step="0.01"
          value={selectedProduct.price || ''}
          onChange={(e) => setSelectedProduct({ ...selectedProduct, price: e.target.value })}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Category:</label>
        <input
          type="text"
          value={selectedProduct.category || ''}
          onChange={(e) => setSelectedProduct({ ...selectedProduct, category: e.target.value })}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Quantity:</label>
        <input
          type="number"
          value={selectedProduct.quantity || ''}
          onChange={(e) => setSelectedProduct({ ...selectedProduct, quantity: e.target.value })}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Image URL:</label>
        <input
          type="text"
          value={selectedProduct.image || ''}
          onChange={(e) => setSelectedProduct({ ...selectedProduct, image: e.target.value })}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>
      <button
        type="submit"
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Save Changes
      </button>
    </form>
  </Modal>
)}
          </div>
        )}
      </div>

      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <h2 className="text-xl">{message}</h2>
          <button 
            onClick={() => setShowModal(false)} 
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
          >
            Close
          </button>
        </Modal>
      )}
    </>
  );
};

export default AdminDashboard;


// function AdminDashboard() {
//   const [confirmations, setConfirmations] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchAllConfirmations = async () => {
//     setIsLoading(true);
//     try {
//       const response = await fetch('/api/get-all-confirmations');

//       if (!response.ok) {
//         throw new Error('Failed to fetch confirmations');
//       }

//       const data = await response.json();
//       setConfirmations(data); // Store confirmations in state
//     } catch (error) {
//       console.error('Error fetching confirmations:', error);
//       setError('Error fetching confirmations');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchAllConfirmations();
//   }, []);

//   return (
//     <div className="admin-dashboard">
//       <h2>Email Confirmations</h2>

//       {isLoading ? (
//         <Spinner />
//       ) : error ? (
//         <p>{error}</p>
//       ) : confirmations.length === 0 ? (
//         <p>No confirmations found.</p>
//       ) : (
//         <table>
//           <thead>
//             <tr>
//               <th>Transaction ID</th>
//               <th>Payer Email</th>
//               <th>Customer Name</th>
//               <th>Shipping Address</th>
//               <th>Promo Code</th>
//               <th>Color Selections</th>
//             </tr>
//           </thead>
//           <tbody>
//             {confirmations.map((confirmation) => (
//               <tr key={confirmation.transaction_id}>
//                 <td>{confirmation.transaction_id}</td>
//                 <td>{confirmation.payer_email}</td>
//                 <td>{confirmation.customer_name}</td>
//                 <td>
//                   {confirmation.shipping_address?.address_line_1}, {confirmation.shipping_address?.admin_area_2},{' '}
//                   {confirmation.shipping_address?.admin_area_1}, {confirmation.shipping_address?.postal_code}, {confirmation.shipping_address?.country_code}
//                 </td>
//                 <td>{confirmation.promo_code || 'None'}</td>
//                 <td>
//                   {Object.entries(confirmation.color_selections || {}).map(
//                     ([key, value]) => (
//                       <p key={key}>{key}: {value}</p>
//                     )
//                   )}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       )}
//     </div>
//   );
// }

// export default AdminDashboard;
