import React from 'react';

function Footer() {
  return (
    <footer className="">
      <div className="container1 mx-auto text-center">
        <p>&copy; 2024 Product Launch. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
