import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Error = () => {
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(10);


    useEffect(() => {
        // Start countdown
        const timer = setInterval(() => {
          setSeconds(prevSeconds => {
            if (prevSeconds <= 1) {
              clearInterval(timer);
              navigate('/cart');
            }
            return prevSeconds - 1;
          });
        }, 1000); // Update every second
    
        // Cleanup the timer if the component unmounts
        return () => clearInterval(timer);
      }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md">
      <div className="flex flex-col items-center text-center">
      <a href="/">
        <img className="max-h-[70px] object-cover rounded-xl mb-4" src="/trojanlogo.png" alt="Trojan Logo" />
      </a>
    
        <h1 className="font-bold text-3xl sm:text-4xl lg:text-4xl mb-2">Treats 4 Trojans</h1>
        <h2 className="text-2xl font-bold text-red-500">Payment Failed!</h2>

      </div>
      <p>There was an error processing your payment. Please try again.</p>
      <p className="mt-4 text-lg">Redirecting in {seconds} seconds...</p>
     
    </div>
    </div>
  );
};

export default Error;






