import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; 
import Navbar from './Navbar';
import Spinner from './Spinner';

const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [discountApplied, setDiscountApplied] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCartItems = async () => {
            const token = localStorage.getItem('access_token');
        
            if (!token) {
                const localCartItems = JSON.parse(localStorage.getItem('cart')) || [];
                setCartItems(localCartItems);
                setLoading(false);
                return;
            }
        
            try {
                const response = await fetch('https://treats4trojansnodeapi.onrender.com/api/cart', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
        
                if (response.ok) {
                    const data = await response.json();
                    const validData = data.map(item => ({
                        id: item.id || null,
                        name: item.name,
                        price: item.price,
                        quantity: item.quantity,
                        image: item.image,
                        category: item.category,
                        selectedColors: [] // Change to store an array of selected colors
                    })).filter(item => item.id !== null);

                    setCartItems(validData);
                } else {
                    const errorData = await response.json();
                    setError(errorData.message || 'Failed to fetch cart items.');
                }
            } catch (error) {
                setError('An error occurred while fetching the cart items.');
            } finally {
                setLoading(false);
            }
        };

        fetchCartItems();
    }, []);

    const updateQuantity = useCallback(async (id, newQuantity) => {
        if (!id) {
            console.error('Invalid product ID.');
            return;
        }

        const token = localStorage.getItem('access_token');
        const updatedCartItems = cartItems.map(item =>
            item.id === id ? { ...item, quantity: newQuantity } : item
        );

        setCartItems(updatedCartItems);

        // Update color selections in local storage
        const colorSelections = JSON.parse(localStorage.getItem('colorSelections')) || {};
        const item = updatedCartItems.find(item => item.id === id);
        
        if (item) {
            const currentColorSelections = item.selectedColors;
            const newColorSelections = Array(newQuantity).fill('');

            // Remove old selections if quantity is decreased
            for (let i = 0; i < Math.min(currentColorSelections.length, newQuantity); i++) {
                newColorSelections[i] = currentColorSelections[i] || ''; 
            }

            updatedCartItems.find(item => item.id === id).selectedColors = newColorSelections;
            localStorage.setItem('colorSelections', JSON.stringify(colorSelections));
        }

        if (token) {
            try {
                const response = await fetch('https://treats4trojansnodeapi.onrender.com/api/cart/update', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ product_id: id, quantity: newQuantity })
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    setError(errorData.message || 'Failed to update cart.');
                    setCartItems(cartItems); 
                }
            } catch (error) {
                setError('An error occurred while updating the cart.');
                setCartItems(cartItems); 
            }
        } else {
            localStorage.setItem('cart', JSON.stringify(updatedCartItems)); 
        }
    }, [cartItems]);

    const removeItem = useCallback(async (id) => {
        if (!id) {
            console.error('Invalid product ID for removal.');
            return;
        }
    
        const token = localStorage.getItem('access_token');
        const updatedCartItems = cartItems.filter(item => item.id !== id);
    
        // Find the item to be removed
        const itemToRemove = cartItems.find(item => item.id === id);
    
        if (itemToRemove && itemToRemove.selectedColors) { // Check if selectedColors exists
            const colorSelections = JSON.parse(localStorage.getItem('colorSelections')) || {};
    
            itemToRemove.selectedColors.forEach((color, index) => {
                const colorKey = `${color}_${itemToRemove.name}_${index + 1}`;
                if (colorSelections[colorKey]) {
                    delete colorSelections[colorKey]; // Remove the color selection
                }
            });
    
            // Update local storage with the new color selections
            localStorage.setItem('colorSelections', JSON.stringify(colorSelections));
        }
    
        setCartItems(updatedCartItems);
    
        if (token) {
            try {
                const response = await fetch('https://treats4trojansnodeapi.onrender.com/api/cart/remove', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ product_id: id })
                });
    
                if (!response.ok) {
                    const errorData = await response.json();
                    setError(errorData.message || 'Failed to remove item.');
                    setCartItems(cartItems); 
                }
            } catch (error) {
                setError('An error occurred while removing the item.');
                setCartItems(cartItems); 
            }
        } else {
            localStorage.setItem('cart', JSON.stringify(updatedCartItems)); 
        }
    }, [cartItems]);
    
    

    const calculateTotalPrice = () => {
        const total = cartItems.reduce((total, item) => total + (item.price || 0) * item.quantity, 0);
        return discountApplied ? total * 0.85 : total;
    };

    const handlePromoCodeSubmit = async () => {
        try {
            const response = await fetch('https://treats4trojansnodeapi.onrender.com/api/promo-codes');
            if (response.ok) {
                const promoCodes = await response.json();

                const matchingPromoCode = promoCodes.find(code => code.code === promoCode);
                if (matchingPromoCode) {
                    setDiscountApplied(true);
                    setDiscountAmount(15);
                    
                    // Store the promo code locally
                    localStorage.setItem('appliedPromoCode', promoCode);
                    
                    // Clear the promo code input
                    setPromoCode('');
                } else {
                    setError('Invalid promo code.');
                    setDiscountApplied(false);
                }
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to verify promo code.');
            }
        } catch (error) {
            setError('An error occurred while verifying the promo code.');
        }
    };

    const updateColor = (itemId, color, index) => {
        const itemIndex = cartItems.findIndex(item => item.id === itemId);
        const updatedCartItems = [...cartItems];
    
        // Check if the item exists and its selectedColors array is properly initialized
        if (itemIndex !== -1) {
            const item = updatedCartItems[itemIndex];
    
            // Initialize selectedColors array if it doesn't exist or doesn't match the quantity
            if (!item.selectedColors) {
                item.selectedColors = Array(item.quantity).fill(''); // Create an array of empty strings with length matching the quantity
            } else if (item.selectedColors.length < item.quantity) {
                // Ensure the length of selectedColors matches the quantity
                item.selectedColors = [...item.selectedColors, ...Array(item.quantity - item.selectedColors.length).fill('')];
            }
    
            // Update the selected color at the given index
            item.selectedColors[index] = color;
    
            // Prepare local storage entry
            const colorKey = `${color}_${item.name}_${index + 1}`;
            const colorSelections = JSON.parse(localStorage.getItem('colorSelections')) || {};
    
            // Update local storage with the new color selection
            colorSelections[colorKey] = color;
            localStorage.setItem('colorSelections', JSON.stringify(colorSelections));
    
            setCartItems(updatedCartItems);
        }
    };
    

    const handleCheckout = () => {
        if (cartItems.length > 0) {
            localStorage.setItem('cart', JSON.stringify(cartItems)); 
        }
        navigate('/pay');
    };

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    if (cartItems.length === 0) {
        return (
            <>
                <Navbar />
                <div className="min-h-screen flex items-center justify-center">
                    <div className="text-center">
                        <h2 className="text-2xl font-bold">Your cart is empty.</h2>
                        <p className="text-gray-700">Add items to your cart to view them here.</p>
                    </div>
                </div>
            </>
        );
    }

    const isLoggedIn = !!localStorage.getItem('access_token');

    // Inside the Cart component
return (
    <>
    <Navbar />
    <div className="max-w-[1240px] mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Your Cart</h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {cartItems.map((item, index) => (
                <div key={`${item.id}-${index}`} className="mb-2 p-4 bg-white rounded shadow">
                    <img src={item.image} alt={item.name} className="w-full h-49 object-cover mb-1" />
                    <h2 className="text-lg font-semibold">{item.name}</h2>
                    <p>Price: ${item.price.toFixed(2)}</p>

                    {/* Check if the item is in the VIDEO SCROLLER RING category */}
                    {item.category === 'VIDEO SCROLLER RING' && (
                        Array.from({ length: item.quantity }).map((_, colorIndex) => {
                            const selectedColors = item.selectedColors || [];
                            const selectedColor = selectedColors[colorIndex] || ''; // Default to an empty string if undefined

                            return (
                                <div key={colorIndex} className="flex items-center">
                                    <span className="mr-2">Select Color {colorIndex + 1}:</span>
                                    <div className="flex space-x-2">
                                        <button
                                            className={`w-8 h-8 rounded-full border ${selectedColor === 'pink' ? 'border-green-500' : ''}`}
                                            style={{ backgroundColor: 'pink' }}
                                            onClick={() => updateColor(item.id, 'pink', colorIndex)}
                                        ></button>
                                        <button
                                            className={`w-8 h-8 rounded-full border ${selectedColor === 'white' ? 'border-green-500' : ''}`}
                                            style={{ backgroundColor: 'white' }}
                                            onClick={() => updateColor(item.id, 'white', colorIndex)}
                                        ></button>
                                        <button
                                            className={`w-8 h-8 rounded-full border ${selectedColor === 'black' ? 'border-green-500' : ''}`}
                                            style={{ backgroundColor: 'black' }}
                                            onClick={() => updateColor(item.id, 'black', colorIndex)}
                                        ></button>
                                    </div>
                                </div>
                            );
                        })
                    )}

                    <div className="flex items-center mb-2">
                        <button
                            className="px-2 py-1 bg-gray-200"
                            onClick={() => updateQuantity(item.id, Math.max(item.quantity - 1, 1))}
                            disabled={item.quantity <= 1}
                        >
                            -
                        </button>
                        <span className="px-4">{item.quantity}</span>
                        <button
                            className="px-2 py-1 bg-gray-200"
                            onClick={() => updateQuantity(item.id, item.quantity + 1)}
                        >
                            +
                        </button>
                    </div>
                    <button
                        className="px-2 py-1 bg-red-500 text-white rounded"
                        onClick={() => removeItem(item.id)}
                    >
                        Remove
                    </button>
                </div>
            ))}
        </div>

        {/* Total Price and Promo Code section */}
        <div className="mt-4">
            <h3 className="text-xl font-bold">Total: ${calculateTotalPrice().toFixed(2)}</h3>
            <div className="mt-2">
                <input
                    type="text"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    placeholder="Enter promo code"
                    className="border rounded px-2"
                />
                <button onClick={handlePromoCodeSubmit} className="bg-blue-500 text-white rounded px-4 py-1 ml-2">Apply</button>
            </div>
            <button
                        className="mt-4 px-4 py-2 rounded bg-orange-500 text-white"
                        onClick={handleCheckout}
                    >
                        Proceed to Checkout
                    </button>
        </div>
    </div>
</>

);
};

export default Cart;
