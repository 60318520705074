import React from 'react'

const Card = () => {

  function VideoComponent({ src, className }) {
    return (
      <video autoPlay loop muted playsInline webkit-playsinline className={className}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
    
  return (
    <>
    <h9 className="text-5xl font-bold max-w-[1240px] m-auto px-4 py-12 flex items-center justify-center ">Fuel your workouts with premium gym essentials</h9>
    <div className='max-w-[1240px] mx-auto p-4 py-12 grid md:grid-cols-3 gap-6'>
      
       <div className="flex gap-5 mt-20 max-md:flex-wrap max-md:pr-5 max-md:mt-10 max-md:max-w-full justify-center md:justify-start">
       
          <h3 className="text-6xl font-bold "> By FIZZIQUE FUEL</h3>
        </div>
        <br></br>
        <div className="rounded-xl cursor-pointer">
              <div className="flex flex-col grow text-4xl font-semibold leading-6 text-white max-md:mt-10 max-md:max-w-full">
                <VideoComponent
                  src="https://trillion-funding-fqgv.onrender.com/uploads/phq.mp4"
                  className="max-h-[180px] md:max-h-[300px] w-full object-cover rounded-xl"
                />
              </div>
            </div>
            
           
            
    </div>
    </>
  )
}

export default Card ;