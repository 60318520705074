import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";


const Hero = () => {
  function VideoComponent({ src, className }) {
    return (
      <video autoPlay loop muted playsInline webkit-playsinline className={className}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
  
  
    return (
      <div className="rounded-xl cursor-pointer">
        <div className="flex flex-col grow text-4xl font-semibold leading-6 text-white max-md:mt-10 max-md:max-w-full">
          <VideoComponent
            src="https://trillion-funding-fqgv.onrender.com/uploads/risengrind2.mp4"
            className="max-w-[1240px] mx-auto md:max-h-[700px] w-full object-cover rounded-xl"
          />
        </div>
      </div>
    );
  
}

export default Hero