import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Card from './components/Card';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Product from './components/Product';
import Login from './components/Login';
import Register from './components/Register';
import Cart from './components/Cart';
import AdminLogin from './components/AdminLogin';
import AdminRegister from './components/AdminRegister';
import AdminDashboard from './components/AdminDashboard';
import Success from './components/Success';
import Error from './components/Error';
import Pay from './components/Pay';
// import Headline from './components/Headline';


const App = () => {
 

  return (
    <Router>
      
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              
              <Card />
              {/* <Headline/> */}
              <Hero />
              <Product />
              <br />
              <Footer />
            </>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/admin/register" element={<AdminRegister/>} />
        <Route path="/admin/login" element={<AdminLogin/>} />
        <Route path="/admin_dashboard" element={<AdminDashboard/>} />
        <Route path="/success" element={<Success />} />
        <Route path="/error" element={<Error />} />
        <Route path="/pay" element={<Pay/>} />
      </Routes>
    </Router>
  );
};

export default App;
